@media print {
    #printableContent {
      margin: 0 auto;
      text-align: center;
    }
  
    .container {
        width: 100%;
        max-width: none;
        margin: 0 auto;
      }
  }

.print-content {
    text-align: center;
    margin: 0 auto;
}